import axios from 'axios'
import React, { useEffect, useState } from 'react'

const TotalDrivers = ({name}) => {
  const [tot,setTotal] = useState(0)

  const fetchTotal =async()=>{
    try {
      const total =await axios.get(
        `${process.env.REACT_APP_URL}/api/rides/total/${name}`
     )
     if(total){
       setTotal(total.data.total)
     }
    } catch (error) {
      console.log(error)
    }
    
  }
  useEffect(()=>{
   fetchTotal()
  },[])
  return (
    <h2>{tot}</h2>
  )
}

export default TotalDrivers