import axios from 'axios'

const fetchDocumentData = async ({ docType, driverId }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/documents/fetch/${docType}/${driverId}`);
        if (!response.data) throw new Error("no response")
        return response.data
    } catch (error) {
        console.error('Error fetching document data:', error.response.data.message);    }
};

export default fetchDocumentData

