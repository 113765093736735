import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Table from '../../components/tableCompopnent/Table'

const Outstationfare = () => {
    const [data, setData] = useState([])
  

    const fetchOutstationfare = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/fetchOutstationroute`)
            if (result.status === 200) {
                console.log(result.data.route)
                setData(result.data.route)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOutstationfare()
    }, [])
    return (
        <div>
            <h2>Routes</h2>
            <Table data={data}   excludeFields={['id','from','to']} />
        </div >
    )
}

export default Outstationfare