import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import RideManagement from "./pages/ride/Ride";
import Driver from "./pages/driver/Driver";
import Login from "./pages/login/Login";
import Dri from "./components/register/Register";
import styles from "./App.module.css"
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/header";
import Customerpayments from "./pages/finance/customer/Customerpayments";
import Driverpayments from "./pages/finance/driver/Driverpayments";
import SelectedRide from "./components/selected/SelectedRide";
import DriverDetails from "./pages/driver/DriverDetails";
import Passenger from "./pages/passenger/Passenger";
import Doc from "./components/ppp/Doc";
import { BookingProvider, DriverProvider } from "./pages/context/DriverContext";
import A from "./components/A/A";
import Outstationroutes from "./pages/outstation/Outstationroutes";
import Booking from "./pages/outstation/outstationBooking/Booking";
import Bookingdetails from "./pages/outstation/outstationBooking/Bookingdetails";
import Outstationfare from "./pages/outstation/Outstationfare";

const getUserCredentials = () => {
  const cookies = document.cookie.split("; ");
  let userCredentials = {};

  cookies.forEach((cookie) => {
    const [name, value] = cookie.split("=");
    if (name === "jwt") {
      userCredentials.token = value;
    }
    if (name === "username") {
      userCredentials.username = value;
    }
    if (name === "role") {
      userCredentials.role = value;
    }
  });

  return userCredentials;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getUserCredentials().token
  );

  const MainLayout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { username, role } = getUserCredentials();

    const toggleSidebar = () => {
      setIsSidebarOpen((prev) => !prev);
    };
    return (
      // <div className={styles.container}>
      <div
        className={`${styles.container} ${isSidebarOpen ? "" : styles["sidebar-collapsed"]
          }`}
      >
        <Header user={{ username, role }} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <Sidebar isOpen={isSidebarOpen} />
        {/* <button
        className={styles.toggleButton}
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        {isSidebarOpen ? "Close" : "Open"}
      </button> */}
        <main className={styles.content}>
          <Outlet />
        </main>
      </div>
    );
  };

  useEffect(() => {
    const checkTokenExistence = () => {
      const { token } = getUserCredentials();
      if (!token) {
        setIsAuthenticated(false);
        window.location.replace("/login");
      }
    };

    if (isAuthenticated) {
      const intervalId = setInterval(checkTokenExistence, 60000);

      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <MainLayout /> : <Navigate to="/login" />}
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/drivers" element={<DriverProvider><Driver /></DriverProvider>} >
            <Route path="/drivers/:driver_ID" element={<DriverDetails />} />
          </Route>
          <Route path="/users" element={<Passenger />} />
          <Route path="/analytics" element={<A />} />
          <Route path="/settings" element={<A />} />
          <Route path="/support" element={<A />} />
          <Route path="/ride-daily" element={<RideManagement />} >
            <Route path="/ride-daily/:trip_id" element={<SelectedRide />} />
          </Route>
          <Route path="/ride-outstation" element={<A />} />
          <Route path="/customer-payments-refunds" element={<A />} />
          <Route path="/outstation-zones" element={<Outstationroutes />} />
          <Route path="/outstation-routes" element={<Outstationfare />} />
          <Route path="/outstation-bookings" element={<BookingProvider ><Booking /></BookingProvider>} >
            <Route path="/outstation-bookings/:trip_id" element={<Bookingdetails />} />
          </Route>
          <Route path="/outstation-trips" element={<A />} />
          <Route path="/driver-payments-refunds" element={< A />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register-drivers.." element={<Dri />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
