import React, { useEffect, useState } from 'react'
import styles from "./selectedRides.module.css"
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const SelectedRide = () => {
  const [details, setDetails] = useState([]);
  const { trip_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRideDetails = async () => {
      try {
        const url = `${process.env.REACT_APP_URL}/api/rides/fetchRideDetails?trip_id=${trip_id}`;
        const response = await axios.get(url);
        console.log(response.data.rideDetails);
        setDetails(response.data.rideDetails);
      } catch (error) {
        console.log("API correct??", error);
      }
    };
    fetchRideDetails();
  }, [trip_id]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <button type="button" className={styles.bbtn} onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <h3> Trip id:</h3> <h4>{trip_id}</h4>
      </div>

      <div className={styles.detailsContainer}>

        <div className={styles.selectedDetails}>
          <span><strong>Source:</strong> {details.source}</span>
          <span><strong>Destination:</strong> {details.destination}</span>
          <span><strong>Completion Status:</strong> {details.completion_status ? details.completion_status : "null"}</span>
          <span><strong>Cancelled by:</strong> {details.cancelled_by ? details.cancelled_by : "null"}</span>
        </div>

        <div className={styles.selectedDetails}>
          <h3 className={styles.head3}>Driver details</h3>
          <span><strong>Driver ID:</strong> {details.driver_ID}</span>
          <span><strong>Driver name:</strong> {details.driver_name}</span>
          <span><strong>Driver contact:</strong> {details.driver_contact}</span>
          <span><strong>Driver ratings:</strong> {details.driver_ratings ? details.driver_ratings : null}</span>
        </div>

        <div className={styles.selectedDetails}>
          <h3 className={styles.head3}>Passenger details</h3>
          <span><strong>Passenger ID:</strong> {details.user_id}</span>
          <span><strong>Passenger name:</strong> {details.user_name}</span>
          <span><strong>Passenger contact:</strong> {details.user_contact}</span>
          <span><strong>Passenger rating:</strong> {details.user_ratings}</span>
        </div>

        <div className={styles.selectedDetails}>
          <h3 className={styles.head3}>Payment details</h3>
          <span><strong>Payment ID:</strong> {details.payment_id ? details.payment_id : "null"}</span>
          <span><strong>Payment method:</strong> {details.payment_method ? details.payment_method : "null"}</span>
          <span><strong>Total amount:</strong> {details.total_amount ? details.total_amount : "null"}</span>
        </div>

        <div className={styles.selectedDetails}>
          <h3 className={styles.head3}>Cab details</h3>
          <span><strong>Cab_id:</strong> {details.cab_id}</span>
          <span><strong>Cab number:</strong> {details.cab_number}</span>
          <span><strong>Cab number:</strong> {details.cab_number}</span>
          <span><strong>Cab number:</strong> {details.cab_number}</span>
          <span><strong>Cab number:</strong> {details.cab_number}</span>
        </div>

      </div>
    </div>
  );
}

export default SelectedRide;
