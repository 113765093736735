import React, { useEffect, useState, useRef } from "react";
import styles from "./documentFile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCheck, faCloudUploadAlt, faRedo, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import MessageDisplay from "../../../components/message/message";
import Doc from "../../../components/ppp/Doc";
import axios from "axios";

const requiredFields = [
    { label: "Pan", name: "pan", type: "file", value: "", path: "" },
    { label: "EPIC", name: "epic", type: "file", value: "", path: "" },
    { label: "Driving License", name: "driving_license", type: "file", value: "", path: "" },
    { label: "PRC", name: "prc", type: "file", value: "", path: "" },
    { label: "Vehicle Permit", name: "vehicle_permit", type: "file", value: "", path: "" },
    { label: "Bank Account", name: "bank", type: "file", value: "", path: "" },
    { label: "Vehicle RC", name: "vehicle_rc", type: "file", value: "", path: "" }
];

const DocumentFile = ({ driver_ID }) => {
    const [formData, setFormData] = useState({ files: {}, uploadStatus: {}, pdfPreviews: {} });
    const [verificationStatus, setVerificationStatus] = useState({});
    const [updatedFields, setUpdatedFields] = useState(requiredFields);
    const [message, setMessage] = useState("");

    const fileInputRefs = useRef(
        requiredFields.reduce((acc, field) => {
            acc[field.name] = React.createRef();
            return acc;
        }, {})
    );

    const fetchData = async () => {
        setFormData({
            files: {},
            uploadStatus: requiredFields.reduce((acc, field) => {
                acc[field.name] = "upload";
                return acc;
            }, {}),
            pdfPreviews: {}
        });

        setUpdatedFields(requiredFields.map((field) => ({
            ...field,
            value: "",
            path: ""
        })));

        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/documents/fetchFile/${driver_ID}`);
            const fileData = response.data;

            const newUploadStatus = {};
            const newVerificationStatus = {};

            const updatedData = requiredFields.map((field) => {
                const matchingDocument = fileData.find((doc) => doc.document_type === field.name);
                if (matchingDocument) {
                    field.value = matchingDocument.document_name;
                    field.path = matchingDocument.path;
                    newUploadStatus[field.name] = "success";
                    newVerificationStatus[field.name] = matchingDocument.verification || "No uploaded documents";
                } else {
                    newUploadStatus[field.name] = "upload";
                    newVerificationStatus[field.name] = "No uploaded documents";
                }
                return field;
            });

            setFormData((prev) => ({
                ...prev,
                uploadStatus: newUploadStatus
            }));

            setVerificationStatus(newVerificationStatus);
            setUpdatedFields(updatedData);
        } catch (error) {
            console.error("Error fetching data:", error);
            const resetUploadStatus = requiredFields.reduce((acc, field) => {
                acc[field.name] = "upload";
                return acc;
            }, {});
            setFormData((prev) => ({
                ...prev,
                uploadStatus: resetUploadStatus
            }));
        }
    };

    useEffect(() => {
        fetchData();
    }, [driver_ID]);

    const handleFileChange = async (e, inputName) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFormData((prev) => ({
                ...prev,
                files: { ...prev.files, [inputName]: selectedFile },
                uploadStatus: { ...prev.uploadStatus, [inputName]: "uploading..." },
                pdfPreviews: { ...prev.pdfPreviews, [inputName]: null }
            }));
            await handleFileUpload(inputName, selectedFile);
        }
    };

    const handleFileUpload = async (inputName, selectedFile) => {
        const token = document.cookie.split("; ").find((row) => row.startsWith("jwt=")).split("=")[1];
        try {
            
            const field = updatedFields.find((field) => field.name === inputName);
            const label = field ? field.label : inputName;
            const formData = new FormData();
            // formData.append("driver_ID", driver_ID);
            formData.append("type", inputName);
            formData.append("file", selectedFile);
            formData.append("name", label);

            const response = await axios.post(
                // `${process.env.REACT_APP_URL}/image/documents/updatefile/${inputName}/${driver_ID}`,
                `${process.env.REACT_APP_UPLOAD}/api/documents/file?driver_Id=${driver_ID}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setTimeout(() => {
                    setUpdatedFields((prevFields) =>
                        prevFields.map((field) =>
                            field.name === inputName
                                ? { ...field, value: response.data.doc_name, path: response.data.path }
                                : field
                        )
                    );
                    setFormData((prevState) => ({
                        ...prevState,
                        files: { ...prevState.files, [inputName]: response.data.doc_name },
                        uploadStatus: { ...prevState.uploadStatus, [inputName]: "success" },
                        pdfPreviews: { ...prevState.pdfPreviews, [inputName]: response.data.path }
                    }));
                    setVerificationStatus((prev) => ({
                        ...prev,
                        [inputName]: "Submitted"
                    }));
                    setMessage({ type: "success", message: response.data.message || "Upload successful" });
                }, 3000);
            }
        } catch (error) {
            setTimeout(() => {
                setFormData((prevState) => ({
                    ...prevState,
                    uploadStatus: { ...prevState.uploadStatus, [inputName]: "retry" },
                    files: { ...prevState.files, [inputName]: null }
                }));
                setMessage({ type: "error", message: error.response?.data?.message || "Upload failed" });
            }, 3000);
        }
    };

    const handleVerificationAction = async (action, docName) => {
        let verification;
        if (action === "verify") {
            verification = "Verified";
        } else if (action === "reject") {
            verification = "Rejected";
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/api/drivers/Verify/${driver_ID}/${docName}`,
                { verification }
            );

            if (response.data.message === "success") {
                setVerificationStatus((prev) => ({
                    ...prev,
                    [docName]: verification
                }));
            }
        } catch (error) {
            setMessage({ type: "error", message: "Verification not successful" });
        }
    };

    const getVerificationLabel = (status) => {
        switch (status) {
            case "Submitted":
                return <span className={`${styles.spa} ${styles.submitted}`}>Submitted</span>;
            case "Rejected":
                return <span className={`${styles.spa} ${styles.rejected}`}>Rejected</span>;
            case "Verified":
                return <span className={`${styles.spa} ${styles.ver}`}>Verified</span>;
            case "Reviewing":
                return <span className={`${styles.spa} ${styles.reviewing}`}>Reviewing</span>;
            default:
                return <span className={`${styles.spa} ${styles.unknown}`}>No uploaded documents</span>;
        }
    };
    const getIcon = (inputName) => {
        switch (formData.uploadStatus[inputName]) {
            case "upload":
                return <FontAwesomeIcon icon={faCloudUploadAlt} />;
            case "uploading...":
                return <FontAwesomeIcon icon={faSpinner} spin />;
            case "retry":
                return <FontAwesomeIcon icon={faRedo} />;
            default:
                return null;
        }
    };
    const handlePdfPreview = (inputName) => {
        const field = updatedFields.find((field) => field.name === inputName);
        if (field && field.path) {
            setFormData((prev) => ({
                ...prev,
                pdfPreviews: { ...prev.pdfPreviews, [inputName]: field.path }
            }));
        }
    };

    const onClose = () => {
        setFormData((prev) => ({
            ...prev,
            pdfPreviews: {}
        }));
    };

    const handleRemoveFile = (inputName) => {
        setFormData((prevState) => ({
            ...prevState,
            files: { ...prevState.files, [inputName]: null },
            uploadStatus: { ...prevState.uploadStatus, [inputName]: "upload" },
            pdfPreviews: { ...prevState.pdfPreviews, [inputName]: null }
        }));

        setUpdatedFields((prevFields) =>
            prevFields.map((field) =>
                field.name === inputName ? { ...field, value: "" } : field
            )
        );
    };

    return (
        <div className={styles.container}>
            <MessageDisplay message={message} />
                <form className={styles.alb1}>
                    <div className={styles.fC}>
                        {updatedFields.map((doc, index) => (
                            <div className={styles.inf} key={index}>
                                <div className={styles.verifyContainer}>
                                    <div className={styles.verified}>
                                        <label>
                                            <FontAwesomeIcon
                                                icon={faCertificate}
                                                className={`${styles.icon} ${formData.uploadStatus[doc.name] !== "success" ? styles.notVerified : styles.iconVerified}`}
                                            />
                                            {formData.uploadStatus[doc.name] === "success" && (
                                                <FontAwesomeIcon icon={faCheck} className={styles.iconTick} />
                                            )}
                                        </label>
                                        <label>{doc.label === "EPIC" ? " (Aadhaar/EPIC)":`${doc.label}`}</label>
                                    </div>
                                    <div className={styles.thatContain}>
                                        {verificationStatus[doc.name] !== "No uploaded documents" && verificationStatus[doc.name] !== "Verified" && verificationStatus[doc.name] !== "Rejected" && (
                                            <>
                                                <button type="button" onClick={() => handleVerificationAction("verify", doc.name)} className={`${styles.validation} ${styles.verify}`}>Verify</button>
                                                <button type="button" onClick={() => handleVerificationAction("reject", doc.name)} className={`${styles.validation} ${styles.reject}`}>Reject</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    name={doc.name}
                                    ref={fileInputRefs.current[doc.name]}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFileChange(e, doc.name)}
                                />
                                 <div className={styles.pdfPicker}>
                                 <>{getVerificationLabel(verificationStatus[doc.name])}</>
                                    
                                    {formData.uploadStatus[doc.name] === "upload" ? (
                                        <button
                                            type="button"
                                            onClick={() => fileInputRefs.current[doc.name]?.current.click()}
                                            className={styles.fileSelect}
                                        >
                                            {getIcon(doc.name)}
                                        </button>

                                    ) : formData.uploadStatus[doc.name] === "success" ? (
                                        <div className={styles.selectedFileName}>
                                            <span onClick={() => handlePdfPreview(doc.name)}>
                                                {doc.label}
                                            </span>
                                            {formData.pdfPreviews[doc.name] && (
                                                <div className={styles.pdf}>
                                                    <Doc pdf={formData.pdfPreviews[doc.name]} onClose={onClose} />
                                                </div >
                                            )}
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveFile(doc.name)}
                                                className={styles.removeFile}
                                            >
                                                <FontAwesomeIcon icon={faXmark} />
                                            </button>
                                        </div>
                                    ) : (
                                        formData.uploadStatus[doc.name] === "retry" ? <button
                                            type="button"
                                            onClick={() => fileInputRefs.current[doc.name]?.current.click()}
                                            className={styles.removeFile}
                                        >
                                            {getIcon(doc.name)} Retry
                                        </button> :
                                            <button
                                                type="button"
                                                className={styles.fileSelect}
                                            >
                                                {getIcon(doc.name)} uploading...
                                            </button>

                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </form>
        </div>
    );
};

export default DocumentFile;
