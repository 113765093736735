import { faStar,  faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faStar as outlineStar } from "@fortawesome/free-regular-svg-icons";
import styles from "./rating.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Rating = ({ rating, totalStars = 5 }) => {
  const finalRating = rating > 5 ? 5 : rating;
  const fullStars = Math.floor(finalRating);
  const hasHalfStar = finalRating % 1 >= 0.5;
  const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
  return (
    <div className={styles.container}>
      {Array(fullStars)
        .fill(null)
        .map((_, i) => (
          <FontAwesomeIcon icon={faStar} className={styles.star} key={i} />
        ))}
      {hasHalfStar && (
        <FontAwesomeIcon className={styles.star} icon={faStarHalfStroke} />
      )}
      {Array(emptyStars)
        .fill(null)
        .map((_, i) => (
          <FontAwesomeIcon icon={outlineStar} className={styles.star} key={i} />
        ))}
    </div>
  );
};

export default Rating;
