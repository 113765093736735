import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from "./pass.module.css"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Passenger = () => {
    const [userDetails, setUserDetails] = useState([])
    const [userDetailsP, setUserDetailsP] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()

    const fetchUsers = async (pageNum) => {
        try {
            if (userDetailsP[pageNum]) {
                setUserDetails(userDetailsP[pageNum]);
                return;
              }
            const fetchedData = await axios.get(
                `${process.env.REACT_APP_URL}/user/profile/details?limit=${"13"}&page=${page}`)
            console.log(fetchedData)

            if (fetchedData) {
                // console.log(fetchedData.data.users)
                setUserDetails(fetchedData.data.users)
                setUserDetailsP((prevData) => ({ ...prevData, [pageNum]: fetchedData.data.users }));
                setTotalPage(Math.ceil(fetchedData.data.total/13))
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUsers(page)
    }, [page])
    return (
        <div className={styles.container}>
            <h2>Passenger</h2>
            <div className={styles.tabContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Email validation</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userDetails.map((user, index) => (
                            <tr key={index}>
                                <td>{user.user_name}</td>
                                <td>{user.user_contact}</td>
                                <td>{user.user_email}</td>
                                <td>{user.user_is_email_valid ? "Yes" : "No"}</td>
                                <td>{user.user_ratings}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className={styles.pagination}>
                <button className={styles.prev} onClick={() => setPage(page - 1)} disabled={page <= 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <span>Page <b>{page}</b> of {totalPage}</span>
                <button className={styles.next} onClick={() => setPage(page + 1)} disabled={page === totalPage}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>

        </div>
    )
}

export default Passenger