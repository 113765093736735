import React, { createContext, useContext, useState } from "react";

const DriverContext = createContext();

export const useDriverContext = () => useContext(DriverContext);

export const DriverProvider = ({ children }) => {
  const [activationClick, setActivationClick] = useState('');
  console.log(activationClick,"from the context provider")
  

  return (
    <DriverContext.Provider value={{ activationClick,setActivationClick}}>
      {children}
    </DriverContext.Provider>
  );
};



const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
    const [data, setData] = useState([]);

    const updateTrip = (tripId, updatedFields) => {

      console.log("reachinghere")
      setData((prevData) =>
          prevData.map((trip) =>
              trip.trip_id === tripId ? { ...trip, ...updatedFields } : trip
          )
      );
  };

    return (
        <BookingContext.Provider value={{ data, setData,updateTrip }}>
            {children}
        </BookingContext.Provider>
    );
};
export const useBookingContext = () => useContext(BookingContext);
