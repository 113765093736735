import React, { useEffect, useState, useRef } from "react";
import styles from "./editabledoc.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faPen, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import MessageDisplay from "../../components/message/message";


const EditableDoc = ({
  document,
  onSubmit,
  docType,
  message,
}) => {
  const [formState, setFormState] = useState({
    files: {},
    tempDocument: [],
  });
  const [initialState, setInitialState] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewDocument, setIsNewDocument] = useState(true);

  const fileInputRefs = useRef({});

  useEffect(() => {
    if (document && document.length > 0) {
      const hasInitialValues = document.some((doc) => doc.value !== "");
      setIsNewDocument(!hasInitialValues);

      const newState = {
        tempDocument: document.map(doc => ({ ...doc, value: doc.value || '' })),
        files: {},
      };

      document.forEach(doc => {
        if (doc.inputType.type === "file") {
          newState.files[doc.name] = null;
          fileInputRefs.current[doc.name] = React.createRef();
        }
      });

      setFormState(newState);
      setInitialState(newState);
      setIsEditing(false);
    }
  }, [document]);

  const handleInputChange = (e, inputName) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      tempDocument: prevState.tempDocument.map((doc) =>
        doc.name === inputName ? { ...doc, value } : doc
      ),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formState.tempDocument.forEach((data) => {
      formData.append(data.name, data.value);
    });

    console.log(isNewDocument ? "insert.." : "update..", docType, formData);
    onSubmit(formData, isNewDocument);
    if(message.type==="error"){
      setFormState(initialState)
    }

    setIsEditing(false);
  };

  const toggleEditMode = () => setIsEditing(!isEditing);

  const cancelEdit = () => {
    setIsEditing(false);
    setFormState(initialState);
  };


  return (
    <div className={styles.container}>
          <MessageDisplay message={message} />
        <div className={styles.buttons}>
          {!isEditing ? (
            <>
              <button className={styles.addUpdateBtn} onClick={toggleEditMode}>
                <FontAwesomeIcon icon={faPen} />
              </button>
              <button className={styles.deleteBtn}>
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </>
          ) : (

            <>
              <button type="submit" onClick={handleSubmit} className={styles.addUpdateBtn}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button className={styles.deleteBtn} onClick={cancelEdit}>
                <FontAwesomeIcon icon={faCancel} />
              </button>
            </>
          )}
        </div>

      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit} className={styles.alb1}>
          <div className={styles.fC}>
            {formState.tempDocument.map((doc, index) => (
              <div className={styles.info} key={index}>
                <label>{doc.label} :</label>
                {isEditing ? (
                  doc.inputType.type === "select" ? (
                    <select
                      name={doc.name}
                      value={doc.value}
                      onChange={(e) => handleInputChange(e, doc.name)}
                    >
                      {doc.options?.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className={`${styles.input}`}
                      type={doc.inputType.type}
                      name={doc.name}
                      placeholder={doc.placeholder}
                      value={doc.value}
                      onChange={(e) => handleInputChange(e, doc.name)}
                    />

                  )
                ) : (
                  <span className={styles.staticValue}>
                    {doc.value || `Add ${doc.label}`}
                  </span>
                )}
              </div>
            ))}
            ------------------ ********************************** ------------------
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditableDoc;
