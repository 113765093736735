import React, { useState, useEffect } from "react";
import styles from "./ride.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faEye, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Modal from "../../components/modal/Modal";
import axios from "axios";

const RideManagement = () => {
  const [isModal, setIsModal] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [groupedRides, setGroupedRides] = useState([]);
  const [totalPage, setTotalPage] = useState('')
  const [page, setPage] = useState(1);


  const [ridesData, setRidesData] = useState({});

  const fetchRides = async (pageNum) => {
    if (ridesData[pageNum]) {
      setGroupedRides(ridesData[pageNum]);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/rides/fetchRides?page=${pageNum}&limit=13`
      );
      const fetchedData = response.data.rides || [];
      const totalItems = response.data.totalList || 0;
      setTotalPage(Math.ceil(totalItems / 13));
      
      console.log("here is the fetched data:", fetchedData)

      setRidesData((prevData) => ({ ...prevData, [pageNum]: fetchedData }));

      setLoading(false);
      if (totalItems < 0)
        setPage(0)
      setGroupedRides(fetchedData);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRides(page);
  }, [page]);

  const handleRideClick = (ride) => {
    navigate(`/ride-daily/${ride.trip_id}`);
  };

  const handleSubmit = async (filteredFormdata) => {
    setError(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/rides/filteredtrips`,
        filteredFormdata
      );
   console.log("filtered ??",response.data.rides)
      setFiltered(response.data.rides);
    } catch (error) {
      setFiltered([]);
      setError(error.response.data.error);
    }
    setIsModal(false);
  };

  const handleRefresh = () => {
    setFiltered([]);
    setError(null);
  };

  const ridesToDisplay = error ? [] :filtered?.length > 0?filtered : groupedRides;
  const isOnRideDetailsPage = location.pathname.startsWith("/ride-daily/");

  return (
    <div className={styles.rm}>
      {!isOnRideDetailsPage ? (
        <>
          <div className={styles.hc}>
            <h3>Ride Management</h3>
            <div className={styles.btnCon}>
              <button className={styles.selectRefresh} onClick={handleRefresh}>
                <FontAwesomeIcon icon={faRefresh} />
              </button>
              <button className={styles.select} onClick={() => setIsModal(true)}>
                Filter by:
              </button>
              {isModal && (
                <div className={`${styles.modal} ${isModal ? styles.modalactive : ""}`}>
                  <Modal setIsModal={setIsModal} onSubmit={handleSubmit} />
                </div>
              )}
            </div>
          </div>

          <div className={styles.ride}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Driver</th>
                  <th>Passenger</th>
                  <th>Trip ID</th>
                  <th>Trip Date</th>
                  <th>Status</th>
                  <th>Cancelled by</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading &&  <Loading />}
                {ridesToDisplay.length > 0 ? ridesToDisplay.map((ride, index) => (
                  <tr>
                    <td>{ride.driver_name}</td>
                    <td>{ride.user_name}</td>
                    <td>.....{ride.trip_id.slice(-10)}</td>
                    <td>{ride.trip_date.slice(0, 10)}</td>
                    <td><span className={`${styles.status} ${ride.completion_status === "completed"  ? styles.completed : ""}`}>{ride.completion_status}</span></td>
                    <td>{ride.cancelled_by || "null"}</td>
                    <td>
                      <button type="button" className={`${styles.buton} ${ride.cancelled_by ? styles.cannotClick : ""}`} onClick={() => handleRideClick(ride)} disabled={ride.cancelled_by}>
                        <FontAwesomeIcon icon={faEye} /> View
                      </button>
                    </td>
                  </tr>
                )):(
                  !loading && <tr>
                    <td colSpan="6" className={styles.centeredDate}>No rides to display</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {ridesToDisplay !== filtered &&<div className={styles.pagination}>
            <button className={styles.prev} onClick={() => setPage(page - 1)} disabled={page <= 1}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span>Page <b>{page}</b>  of  {totalPage}</span>
            <button className={styles.next} onClick={() => setPage(page + 1)} disabled={page === totalPage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>}
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default RideManagement;