import React, { useEffect, useState } from 'react'
import Table from '../../../components/tableCompopnent/Table'
import axios from 'axios'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useBookingContext } from '../../context/DriverContext'

const Booking = () => {
    const {data, setData} = useBookingContext()
    const navigate = useNavigate();
    const location = useLocation();

    const fetchOutstationTrips = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/fetchOutstationtrips`)
            if (result.status === 200) {
                console.log(result.data.trips)
                setData(result.data.trips)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOutstationTrips()
    }, [])

    const handleNavigation = (trip_id,latlng,vehicle,driver_id) => {
        navigate(`/outstation-bookings/${trip_id}`,{state:{latlng,vehicle,driver_id}});
    }

    const isOnBooking = location.pathname.startsWith("/outstation-bookings/");

    return (
        <div>
         {!isOnBooking?<>
            <h2>Booking</h2>
            <Table data={data} handleNavigation={handleNavigation}  excludeFields={['latlng','trip_id','route_id']} />
            </>
        : (
            <Outlet />
        )}
        </div >
    )
}

export default Booking