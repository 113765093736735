import React from 'react';
import styles from "./table.module.css"

const Table = ({ data, handleNavigation, excludeFields = [] }) => {
  if (!data || data.length === 0) {
    return <p>No booking available</p>;
  }

  // Extract headings from object keys
  const headings = Object.keys(data[0]).filter(key => !excludeFields.includes(key));
  console.log(headings)

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headings.map((heading, index) => (
              <th key={index}>{heading.replace('_', ' ').charAt(0).toUpperCase() + heading.slice(1)}</th>
            ))}
            {handleNavigation && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headings.map((key, colIndex) => (
                <td key={colIndex}>
                  {typeof row[key] === "object"
                    ? JSON.stringify(row[key])
                    : row[key]}
                </td>
              ))}
              {handleNavigation &&
                <td><button type="button" onClick={() => handleNavigation(row.trip_id,row.latlng,row.vehicle,row.driver_id)} className={styles.buton}>Assign Driver</button></td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
