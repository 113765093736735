import React from 'react'
import styles from "./loading.module.css"

const Loading = () => {
  return (
    <dvi className={styles.loadContainer}>
      <div className={styles.load}></div>
    </dvi>
  )
}

export default Loading