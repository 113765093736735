import React, { useState } from "react";
import styles from "./register.module.css";
import {
  getLabel,
  getInputType,
  getSelectOptions,
} from "../../helper/editabledoc/InputFunctions";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

const Dri = () => {
  const fields = [
    "driver_name",
    "driver_age",
    "driver_gender",
    "driver_contact",
    "driver_address",
    "driver_email",
    "password",
    "re_password",
  ];
  
// see these changes?

  const [formState, setFormState] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field]: "" }), {})
  );
  const [message, setMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);

  const validatePasswords = (password, re_password) => {
    if (password && re_password && password !== re_password) {
      setMessage({ type: "error", message: "Passwords do not match" });
      setIsPasswordMismatch(true);
    } else {
      setMessage(null);
      setIsPasswordMismatch(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    delete formState.re_password;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/drivers/register`,
        formState
      );
      setMessage({ type: "success", message: response.data.message });
    } catch (error) {
      console.log(error)
      setMessage({
        type: "error",
        message: error.response?.data.message || "An error occurred",
      });
    } finally {
      setTimeout(() => setMessage(null), 3000);
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setFormState((prev) => ({ ...prev, [field]: value }));
    if (field === "password" || field === "re_password") {
      validatePasswords(
        field === "password" ? value : formState.password,
        field === "re_password" ? value : formState.re_password
      );
    }
  };

  const toggleVisibility = (field) => {
    if (field === "password") setShowPassword(!showPassword);
    if (field === "re_password") setShowRePassword(!showRePassword);
  };

  return (
    <div className={styles.container}>
      <form className={styles.frm} onSubmit={handleSubmit}>
        <div className={styles.form}>
          <h1 className={styles.h1}>Registration</h1>
          {fields.map((field) => {
            const label = getLabel(field);
            const placeholder = `Enter ${getLabel(field)}`;
            const inputType = getInputType(field);
            const options = getSelectOptions(field);
            const isPasswordField =
              field === "password" || field === "re_password";
            const inputTypeToShow = isPasswordField
              ? (field === "password" && showPassword) ||
                (field === "re_password" && showRePassword)
                ? "text"
                : "password"
              : inputType.type;

            return (
              <div className={styles.line} key={field}>
                <label>{label}</label>
                {inputType.type === "select" ? (
                  <div className={styles.inputContainer}>
                    <select
                      className={styles.select}
                      name={field}
                      value={formState[field]}
                      onChange={(e) => handleInputChange(e, field)}
                    >
                      {options.map((option) => (
                        <option
                          className={styles.options}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className={styles.inputContainer}>
                    <input
                      type={inputTypeToShow}
                      maxLength={inputType.maxLength}
                      placeholder={placeholder}
                      name={field}
                      value={formState[field]}
                      className={styles.input}
                      onChange={(e) => handleInputChange(e, field)}
                    />
                    {isPasswordField && (
                      <div
                        className={styles.toggleButton}
                        onClick={() => toggleVisibility(field)}
                      >
                        {inputTypeToShow === "password" ? (
                          <FontAwesomeIcon icon={faLock} className={styles.eye} />
                        ) : (
                          <FontAwesomeIcon icon={faLockOpen} className={styles.eye} />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.mesCon}>
          <div className={styles.message}>
            {message && (
              <div
                className={`${
                  message.type === "error" ? styles.error : styles.success
                }`}
              >
                {message.message}
              </div>
            )}
          </div>
          <button
            type="submit"
            className={styles.submitButton}
            disabled={isPasswordMismatch}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Dri;
