import React, { useEffect, useState } from 'react'
import styles from "./outstation.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import MessageDisplay from '../../components/message/message';

const Outstationroutes = () => {
  const [zones, setZones] = useState([])
  const [message,setMessage] = useState()

  const fetchZones = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/fetchzones`)

      if (result.data.zones) {
        console.log(result.data)

        setZones(result.data.zones)
      }
    } catch (error) {
      console.log(error)
    }
   
  }
  useEffect(() => {
    fetchZones()
  }, [])

  const [newZone, setNewZone] = useState({ name: '', boundary: '' });

  // const handleInputChange = (index, field, value) => {
  //   const updatedZones = [...zones];
  //   updatedZones[index][field] = value;
  //   setZones(updatedZones);
  //   console.log(updatedZones)
  // };

  const addZone = async () => {
    if (newZone.name && newZone.boundary) {
      console.log(newZone)

      try {
        const add = await axios.post(`${process.env.REACT_APP_URL}/api/outstation/addpolygon`, newZone)
        if (add.status === 200) {
          setMessage({ type: "success", message: add.data.message });

          setZones([...zones, newZone]);
          setNewZone({ name: '', boundary: '' });
        }
      } catch (error) {
        console.log(error)
      }
    }
  };
  const handleNewZoneChange = (field, value) => {
    setNewZone((prev) => ({ ...prev, [field]: value }));
  };

  const handleZoneDelete = async(index,zonename) =>{
  console.log("clicked",index,zonename)
  try {
    const deleteZone = await axios.delete(`${process.env.REACT_APP_URL}/api/outstation/deleteZone/${zonename}`)
    if(deleteZone.status === 200){
      // setMessage(deleteZone.data.message)
      setMessage({ type: "success", message: deleteZone.data.message });

      setZones((prevZones) => prevZones.filter((zone, i) => i !== index));
    }
  } catch (error) {
    console.log(error)
  }
  }

  return (
    <div className={styles.contain}>
      <h2>Outstation zones</h2>
      <div className={styles.addContainer}>
        <label htmlFor="zoneInput">
          Zone
          <input
            type="text"
            id="zoneInput"
            value={newZone.name}
            onChange={(e) => handleNewZoneChange('name', e.target.value)}
          />
        </label>

        <label htmlFor="boundary">
          Boundary
          <input
            type="text"
            id="boundary"
            value={newZone.boundary}
            onChange={(e) => handleNewZoneChange('boundary', e.target.value)}
          />
        </label>
        <button type="button" onClick={addZone}>
          Add
        </button>
      </div>
      <div className={styles.dcontainer}>
        <MessageDisplay message={message} />
        <table className={styles.table}>

          <thead>
            <tr>
              <th>Zone</th>
              <th>Boundary</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {zones.map((zone, index) => (
              <tr>
                {/* <td><input type="text"   onChange={(e) => handleInputChange(index, 'name', e.target.value)} value={zone.name} /></td>
                <td><input type="text"   onChange={(e) => handleInputChange(index, 'boundary', e.target.value)} value={zone.boundary} /></td> */}
                <td> {zone.name}</td>
                <td><input type="text" value={JSON.stringify(zone.boundary)} readOnly /></td>
                <td> <button type="button" className={styles.delete} onClick={() => handleZoneDelete(index,zone.name)}><FontAwesomeIcon icon={faTrash} /></button></td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Outstationroutes