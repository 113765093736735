const selectOptions = {
  driver_gender: [
    { label: "-- Select Gender --", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ],
  gender: [
    { label: "-- Select Gender --", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ],
  medium: [
    { label: "-- Select Medium --", value: "" },
    { label: "Car", value: "Car" },
    { label: "Bike", value: "Bike" },
    { label: "Others", value: "others" },
  ],
  blood_group: [
    { label: "-- Select Blood Group --", value: "" },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "Others", value: "others" },
  ]

};
export const getSelectOptions = (key) => selectOptions[key] || [];



const inputTypes = {
  numberFields: ["aadhaar_no", "driver_contact", "age", "driver_age","contact1","contact2"],
  selectFields: ["driver_gender", "gender", "medium", "blood_group"],
  emailFields: ["driver_email"],
  passFields: ["password", "re_password"],
  documentpdf: ["pdf"],
   // dateFields: [
  //   "dob", "driver_dob",
  //   "date_of_issue", "valid_upto", "issue_date",
  //   "inspect_on", "expire_on", "inspection_due",
  //   "validity",
  // ],

};
export const getInputType = (key) => {
  if (inputTypes.numberFields.includes(key)) {
    if (key === "driver_contact") return { type: "tel", maxLength: 10 };
    return { type: "number" };
  }
  if (inputTypes.selectFields.includes(key)) return { type: "select" };
  // if (inputTypes.dateFields.includes(key)) return { type: "date" };
  if (inputTypes.emailFields.includes(key)) return { type: "email" };
  if (inputTypes.passFields.includes(key)) return { type: "password" };
  if (inputTypes.documentpdf.includes(key)) return { type: "file", accept: ".pdf" };
  return { type: "text" };
};
const labels = {
  driver_name: "Name",
  driver_age: "Age",
  driver_gender: "Gender",
  driver_contact: "Contact",
  driver_address: "Address",
  driver_email: "E-mail address",
  password: "Password",
  re_password: "Re-enter password",

  reg_no: "Registration no",
  reg_date: "Registration Date",
  owner: "Owner",
  address: "Address",
  chassis_no: "Chassis no",
  engine_no: "Engine no",
  model_no: "Model no",
  color: "Color",
  mfg_date: "Manufactured date",

  cab_status: "Cab status",
  cab_number: "Cab no",
  cab_colour: "Cab color",
  cab_model: "Cab Model",
  medium: "Medium",

  Bank_name: "Bank name",
  Account_no: "Account no",
  IFSC_code: "IFSC code",
  Acc_holder_name: "Acc holder name",
};
export const getLabel = (key) => labels[key] || "";
