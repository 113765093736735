import React, { useEffect, useState } from "react";
import {
  getInputType,
  getSelectOptions,
} from "./InputFunctions";
import EditableDoc from "./editabledoc";
import fetchDocumentData from "./EditableFetch";
import axios from "axios";
import DocumentFile from "./DocumentFile";

const DocumentHandler = ({ docType, driverId }) => {
  const [documentData, setDocumentData] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    console.log(docType);

    const fetchData = async () => {
      try {
        const data = await fetchDocumentData({ docType, driverId });
        const fetchedData = data?.length > 0 ? data[0] : getDefaultData(docType);
        const newDocument = Object.entries(fetchedData)
          .filter(([key]) => !["cab_id", "password", "driver_ID","first_name","driver_ratings","last_name","middle_name","driver_age", "verification", "refresh_token", "cab_status", "creation_date", "activation", "id"].includes(key))
          .map(([key, value]) => ({
            label: key.replace(/_+/g, ' ').replace(/^./, char => char.toUpperCase()),
            name: key,
            placeholder: `Enter ${key.replace(/_+/g, ' ')}`,
            value: value || "",
            inputType: getInputType(key),
            options: getSelectOptions(key),
          }));


        setDocumentData(newDocument);
      } catch (error) {
        console.error("Error fetching document data:", error);
      }
    };

    fetchData();
  }, [driverId, docType]);
  const getDefaultData = (type) => {
    const defaults = {
      cabs: {
        cab_number: "",
        cab_colour: "",
        cab_model: "",
        medium: ""
      },
      bank: {
        bank_name: "",
        account_no: "",
        ifsc: "",
        holder_name: "",
        branch: ""
      },
      car_rc: {
        reg_no: "",
        owner: "",
        model_no: "",
        color: "",
      },
      driving_license: {
        license_no: "",
        valid_upto: "",
        issue_date: ""
      },
      family_contact: {
        name1: "",
        address1: "",
        contact1: "",
        name2: "",
        address2: "",
        contact2: "",
      }
    };
    return defaults[type] || {};
  };

  const handleFormSubmit = async (formData, isNewDocument) => {
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwt="))
      .split("=")[1];
    try {
      const url = isNewDocument
        ? `${process.env.REACT_APP_URL}/api/documents/insertNew/${docType}`
        : `${process.env.REACT_APP_URL}/api/documents/update/${docType}/${driverId}`;

      let formDataObj = {};
      if (isNewDocument) {
        formDataObj = { "driver_ID": driverId }
        for (const [key, value] of formData.entries()) {
          formDataObj[key] = value;
        }
      }
      else {
        for (const [key, value] of formData.entries()) {
          formDataObj[key] = value;
        }
      }
      const response = await axios.post(url, formDataObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        console.log(response.data.message, "success");
        setMessage({ type: "success", message: response.data.message });
      }
    } catch (error) {
      console.log(error)
      if (error.response.data.message)
        setMessage({ type: "error", message: error.response.data.message });
    }
  };

  return (
    
    <EditableDoc
      document={documentData}
      onSubmit={handleFormSubmit}
      docType={docType}
      driver_ID={driverId}
      message={message}
    />
  );
};

export default DocumentHandler;
