import React, { useEffect, useState } from "react";
import styles from "./message.module.css";

const MessageDisplay = ({ message }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!message) return null;
  const messageClass =
    message.type === "error" ? `${styles.error}` : `${styles.success}`;
  return (
    <div
      className={`${styles.message} ${messageClass} ${show ? styles.show : ""}`}
    >
      {message.message}
    </div>
  );
};

export default MessageDisplay;
