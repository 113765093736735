import React, { useState } from "react";
import styles from "./sidebar.module.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookBookmark,
  faCab,
  faChartSimple,
  faCoins,
  faGear,
  faLink,
  faMoneyBill,
  faRightToBracket,
  faRoad,
  faRoute,
  faSuitcaseRolling,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faSquarespace, faStripe, faStripeS } from "@fortawesome/free-brands-svg-icons";
import { faCarSide } from "@fortawesome/free-solid-svg-icons/faCarSide";
const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const onLogoutClick = () => {
    document.cookie = "jwt=; path=/; max-age=0;";
    document.cookie = "username=; path=/; max-age=0;";
    window.location.replace("/");
  };

  const [sections, setSections] = useState({
    rides: false,
    financial: false,
    outstation: false,
  });

  const toggleSection = (section) => {
    setSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  // const handleShow = () => {
  //   setShow((prevShow) => !prevShow);
  // };

  const getClassNames = (path) => {
    return `${isOpen ? styles["nav-btn"] : styles.notOpen} ${isActive(path) ? isOpen ? `${styles.active} ${styles["disable-hover"]}` : `${styles.notOpenactive} ${styles["disable-hover"]}` : ""}`;
  };

  const getSubClassNames = (path) => {
    return `${isOpen ? styles["sub-link"] : styles.notOpen} ${isActive(path) ? isOpen ? `${styles.active} ${styles["disable-hover"]}` : `${styles.notOpenactive} ${styles["disable-hover"]}` : ""}`;
  };

  return (
    <aside className={styles.sidebar}>


      <div className={styles.not}>
        <div className={styles.btn}>
          <Link to="/" className={getClassNames("/")}>
            <FontAwesomeIcon icon={faSquarespace} className={styles.btnIcon} />
            {isOpen && <span>Dashboard</span>}
          </Link>

          <div onClick={() => toggleSection("rides")} className={`${getClassNames("/rides..")}`}>
            <FontAwesomeIcon icon={faCarSide} className={styles.btnIcon} />
            {isOpen && <span>Ride</span>}
          </div>

          {sections.rides && (
            <>
              <Link to="/ride-daily" className={getSubClassNames("/ride-daily")}>
                <FontAwesomeIcon icon={faUser} className={styles.btnIcon} />
                {isOpen && <span>Daily</span>}
              </Link>

              <Link to="/ride-outstation" className={getSubClassNames("/ride-outstation")}>
                <FontAwesomeIcon icon={faUser} className={styles.btnIcon} />
                {isOpen && <span>Outstation</span>}
              </Link>
            </>
          )}

          <Link to="/drivers" className={getClassNames("/drivers")}>
            <FontAwesomeIcon icon={faCab} className={styles.btnIcon} />
            {isOpen && <span>Driver</span>}
          </Link>

          <Link to="/users" className={getClassNames("/users")}>
            <FontAwesomeIcon icon={faUser} className={styles.btnIcon} />
            {isOpen && <span>Passenger</span>}
          </Link>
          <div onClick={() => toggleSection("outstation")} className={`${getClassNames("/outstation")}`}>
          <FontAwesomeIcon icon={faSuitcaseRolling}  className={styles.btnIcon} />
            {isOpen && <span>Outstation</span>}
          </div>

          {sections.outstation && (
            <>
              <Link to="/outstation-zones" className={getSubClassNames("/outstation-zones")}>
                <FontAwesomeIcon icon={faRoute} className={styles.btnIcon} />
                {isOpen && <span>Zones</span>}
              </Link>

              <Link to="/outstation-routes" className={getSubClassNames("/outstation-routes")}>
                <FontAwesomeIcon icon={faMoneyBill} className={styles.btnIcon} />
                {isOpen && <span>Route</span>}
              </Link>

              <Link to="/outstation-bookings" className={getSubClassNames("/outstation-bookings")}>
                <FontAwesomeIcon icon={faBookBookmark} className={styles.btnIcon} />
                {isOpen && <span>Bookings</span>}
              </Link>

              <Link to="/outstation-trips" className={getSubClassNames("/outstation-trips")}>
                <FontAwesomeIcon icon={faRoad} className={styles.btnIcon} />
                {isOpen && <span>Trips</span>}
              </Link>
            </>
          )}

          <div onClick={() => toggleSection("financial")} className={`${getClassNames("/customer-driver-payments-refunds")}`}>
            <FontAwesomeIcon icon={faCoins} className={styles.btnIcon} />
            {isOpen && <span>Financial</span>}
          </div>

          {sections.financial && (
            <>
              <Link to="/customer-payments-refunds" className={getSubClassNames("/customer-payments-refunds")}>
                <FontAwesomeIcon icon={faUser} className={styles.btnIcon} />
                {isOpen && <span>Customers</span>}
              </Link>

              <Link to="/driver-payments-refunds" className={getSubClassNames("/driver-payments-refunds")}>
                <FontAwesomeIcon icon={faUser} className={styles.btnIcon} />
                {isOpen && <span>Drivers</span>}
              </Link>
            </>
          )}

          <Link to="/analytics" className={getClassNames("/analytics")}>
            <FontAwesomeIcon icon={faChartSimple} className={styles.btnIcon} />
            {isOpen && <span>Analytics reporting</span>}
          </Link>

          <Link to="/settings" className={getClassNames("/settings")}>
            <FontAwesomeIcon icon={faGear} className={styles.btnIcon} />
            {isOpen && <span>System settings</span>}
          </Link>

          <Link to="/support" className={getClassNames("/support")}>
            <FontAwesomeIcon icon={faLink} className={styles.btnIcon} />
            {isOpen && <span>System maintenance</span>}
          </Link>
        </div>
      </div>

      <button className={`${isOpen ? styles.button : styles.notOpenbut} `}
                             
        onClick={onLogoutClick}>
        {isOpen && <span>Logout</span>}
        <FontAwesomeIcon icon={faRightToBracket} className={styles.btnIcon} />
      </button>
    </aside>
  );
};

export default Sidebar;
