import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./dd.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import New from './profile/Profile';
import DocumentFile from './documentfile/DocumentFile';
import DocumentHandler from '../../helper/editabledoc/doc';

const DriverDetails = () => {
    const { driver_ID } = useParams();
    const navigate = useNavigate();
    const [selectedDocType, setSelectedDocType] = useState('document_file');

    const handleBack = () => {
        navigate(-1);
    };

    const documentTypes = [
        "document_file", "driver", "cabs", "bank", "family_contact",

    ];

    const handleDocTypeClick = (docType) => {
        setSelectedDocType(docType);
    };
    

    const handleActivationChange = (driver_ID, newStatus) => {
        console.log(`Driver ${driver_ID} is now ${newStatus}`);
        // Perform any other logic like updating the parent state
    };


    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <button type="button" className={styles.bbtn} onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /></button>
                <h3> Driver ID:</h3> <h4>{driver_ID}</h4>
            </div>
            <div className={styles.driverDetails}>
                <div className={styles.profile}>{
                    <New driver_ID={driver_ID} onActivationChange={handleActivationChange} />}
                </div>
                <div className={styles.detail}>
                    <div className={styles.de}>
                        <h3>Select a document</h3>

                        <div className={styles.details2}>
                            {
                                documentTypes.map((docType) => (
                                    <button type="button"
                                        key={docType}
                                        onClick={() => handleDocTypeClick(docType)}
                                        className={selectedDocType === docType ? styles.activeBtn : styles.bttn}                                    >
                                        {docType === "cabs"
                                            ? "Cab"
                                            : docType.replace(/_+/g, ' ').replace(/^./, char => char.toUpperCase())} details</button>
                                ))
                            }
                        </div>
                    </div>
                    <div className={styles.de2}>
                        <h3>{selectedDocType === "cabs" ? "Cab" : selectedDocType.replace(/_+/g, ' ').replace(/^./, char => char.toUpperCase())}</h3>
                        {
                            <div className={styles.details}>
                                {
                                    selectedDocType === "document_file" ?
                                        <DocumentFile driver_ID={driver_ID} /> :
                                        selectedDocType && <DocumentHandler docType={selectedDocType} driverId={driver_ID} />
                                }
                            </div>
                        }
                    </div>


                </div>
            </div>
        </div>
    )
}

export default DriverDetails