import React from 'react';
import styles from './dashboard.module.css';
import TotalDrivers from '../../components/totalDrivers/TotalDrivers';


const cards = [
  { id: 1, classname: styles.card, title: "Total Drivers", name: "driver", description: <TotalDrivers name={"driver"} /> },
  { id: 2, classname: styles.card, title: "Total Customers", name: "rider", description: <TotalDrivers name={"rider"} /> },
  { id: 3, classname: styles.card, title: "Total Trips", name: "trips", description: <TotalDrivers name={"trips"} /> },
  { id: 4, classname: styles.card, title: "Medium", name: "Medium", description: <TotalDrivers name={"medium"} /> },
  { id: 4, classname: styles.card, title: "Car", name: "car", description: <TotalDrivers name={"2"} /> },
  { id: 4, classname: styles.card, title: "Bike", name: "Bike", description: <TotalDrivers name={"1"} /> },
  { id: 4, classname: styles.card, title: "Premium", name: "Premium", description: <TotalDrivers name={"3"} /> }
];

const Dashboard = () => {
  return (
    <>
      <h2>Dashboard</h2>
      <div className={styles.contain}>
        {cards.map((card, index) => (
          <div key={index} className={card.classname}>
            <h4>{card.title}</h4>
            <div className={styles.carddes}>
              {card.description}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Dashboard;
