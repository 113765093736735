import React from 'react'
import styles from './pp.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Doc = ({ pdf,onClose }) => {
    console.log("pdf:",pdf)
    // const baseUrl = `${process.env.REACT_APP_URL}/image/documents/fetch?imgpath=${pdf}`
    const baseUrl = `${process.env.REACT_APP_SIR}/`+pdf
    

    // const pdfSrc = pdf.startsWith("blob:") ? pdf : `${baseUrl}`;
    const pdfSrc =`${baseUrl}`;
    return (<>
        <iframe
            src={pdfSrc}
            title="PDF Preview"
            type="application/pdf"
            className={styles.pdf}
        />
        <button type="button" onClick={onClose}className={styles.button}><FontAwesomeIcon icon={faXmark} /></button>
    </>
    );
}

export default Doc