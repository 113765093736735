import Compressor from 'compressorjs';

 const CompressImage = (file, quality = 0.1) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided for compression.'));
      return;
    }

    new Compressor(file, {
      quality,
      success: (compressedFile) => {
        resolve(compressedFile);
      },
      error: (err) => {
        reject(err);
      },
    });
  });
};

export default CompressImage

