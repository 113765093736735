import React, { useState } from 'react';
import styles from "./modal.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ setIsModal, onSubmit }) => {
    const [checkboxState, setCheckboxState] = useState({
        date: false,
        status: false,
    });

    const [inputs, setInputs] = useState({
        fromDate: '',
        toDate: '',
        status: ''
    });
    const [error, setError] = useState()


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
        console.log(value)

        if (name === 'fromDate' || name === 'toDate') {
            const { fromDate, toDate } = {
                ...inputs,
                [name]: value,
            };

            // Check if start date is greater than end date
            if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
                setError('Start date cannot be greater than end date.');
            }
            else {
                setError(null)
            }
        }

    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setCheckboxState((prevState) => ({
            ...prevState,
            [value]: checked,
        }));

    };

    const handleFilterSubmit = () => {
        const filterData = {
            ...(checkboxState.date && { fromDate: inputs.fromDate, toDate: inputs.toDate }),
            ...(checkboxState.status && { status: inputs.status }),
        };

        const filterFormData = Object.fromEntries(Object.entries(filterData).filter(([_, v]) => v))

        onSubmit(filterFormData);
    };




    const isFilterEnabled = checkboxState.date || checkboxState.status

    return (
        <div className={styles.modalContainer}>
            <>Filter trips with..</>
            <div className={styles.checkA}>
                <input className={styles.check} type="checkbox" value="date" onChange={handleCheckboxChange} /> Date
            </div>
            {checkboxState.date && (
                <div className={styles.dateRange}>
                    <label>
                        Start  : <input type="date" placeholder="Enter date..." name="fromDate" onChange={handleInputChange} value={inputs.fromDate} />
                    </label>
                    <label>
                        End : <input type="date" placeholder="Enter date..." name="toDate" onChange={handleInputChange} value={inputs.toDate} />
                    </label>
                </div>
            )}
            <label>
                {error && <div className={styles.error}>{error}</div>}
            </label>
            <div className={styles.checkA}>
                <input className={styles.check} type="checkbox" value="status" onChange={handleCheckboxChange} />
                Status
            </div>

            {checkboxState.status && (
                <div className={styles.status}>
                    <label>

                        <input type="radio" className={styles.check} name="status" placeholder="Enter status..." onChange={handleInputChange} value="completed" />Success
                    </label>
                    <label>

                        <input type="radio" className={styles.check} name="status" placeholder="Enter status..." onChange={handleInputChange} value="cancelled" />Cancelled
                    </label>
                </div>
            )}

            <button onClick={() => setIsModal(false)} className={styles.bbt} >
                <FontAwesomeIcon icon={faXmark} />
            </button>

            <button className={styles.filterBtn} onClick={handleFilterSubmit} type="submit" disabled={!isFilterEnabled || error} >Filter</button>
        </div>
    );
};

export default Modal;
