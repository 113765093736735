import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './Booking.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Loading from '../../../components/loading/Loading';
import { useBookingContext } from '../../context/DriverContext';
// import styles from './bookingDetails.module.css'

const Bookingdetails = () => {
    const location = useLocation();
    const { latlng, vehicle, driver_id } = location.state || {};
    const { trip_id } = useParams();
    const navigate = useNavigate();
    const [driver, setDriver] = useState([]);
    const [tripDetails, setTripDetails] = useState([])
    const [selected, setSelected] = useState('')

    const { updateTrip } = useBookingContext();
    const [driverDetail, setDriverDetail] = useState([])


    const handleBack = () => {
        navigate(-1);
    };
    const handleSearch = async (latlng, vehicle) => {
        console.log(latlng, vehicle)
        try {
            const searchDriver = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/searchODriver`,
                { params: { latlng, vehicle }, }
            )
            if (searchDriver) {
                setDriver(searchDriver.data.drivers)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async () => {
        try {
            const fetchedData = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/fetchDetails/${trip_id}`)
            if (fetchedData.data.details) {
                console.log(fetchedData.data.details)
                setTripDetails(fetchedData.data.details)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const driverDetails = async (driver_id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/outstation/driverDetails/${driver_id}`)
            if (response.data.driverDet) {
                console.log("assigned driver details", response.data.driverDet)
                setDriverDetail(response.data.driverDet)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
        if (!driver_id) {
            handleSearch(latlng, vehicle)
        } else {
            driverDetails(driver_id)
        }


    }, [])


    const handleSelectDriver = (driverid) => {
        setSelected(driverid)
    }
    const handleSelection = async () => {

        try {
            const formData = {
                driver_id: selected,
                trip_id: trip_id
            }
            console.log(formData)
            const assignDriver = await axios.post(`${process.env.REACT_APP_URL}/api/outstation/assignDriver`, formData)
            console.log(assignDriver.status)
            if (assignDriver.status === 200) {
                console.log("before going to the context")
                updateTrip(trip_id, { driver_id: selected, status: 'Confirmed' });
                driverDetails(selected);
                setDriver([])
            }
        } catch (error) {
            console.error('Error assigning driver:', error);
        }

    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <button type="button" className={styles.bbtn} onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /></button>
                <h3>Outstation Trip_id:</h3> <h4>{trip_id}</h4>
            </div>

            <div className={styles.contain}>

                {tripDetails.length > 0 && (
                    <div className={styles.tripDetails}>
                        <h3>Trip Details</h3>
                        {tripDetails.map((detail, index) => (
                            <div key={index} className={styles.tripDetail}>
                                customer
                                <p><strong>Email:</strong> {detail.contacts.email}</p>
                                <p><strong>Contact:</strong> {detail.contacts.contact}</p>
                                <p><strong>Additional Contact:</strong> {detail.contacts.additional_contact}</p>
                                journey
                                <p><strong>Origin:</strong> {detail.origin.address}</p>
                                <p><strong>Destination:</strong> {detail.destination.address}</p>
                                <p><strong>Pickup Time:</strong> {detail.times.pickup_time}</p>
                                <p><strong>Pickup location:</strong> {detail.pickup.address}</p>
                                <p><strong>Vehicle:</strong> {detail.vehicle}</p>
                                <p><strong>Type:</strong> {detail.journey_type}</p>
                                <p><strong>Status:</strong> {detail.status}</p>
                                <p><strong>Depart Date:</strong> {detail.dates.departDate}</p>
                                {detail.dates.returnDate && <p><strong>Return Date:</strong> {detail.dates.returnDate}</p>}
                               
                                <p><strong>Distance:</strong> {detail.distance}</p>
                                <p><strong>Duration:</strong> {detail.duration}</p>
                                Price
                                <p><strong>Base fare:</strong> {detail.breakdown_prices.base_fare}</p>
                                <p><strong>Total hours price:</strong> {detail.breakdown_prices.total_hours_price}</p>
                                <p><strong>Driver allowance:</strong> {detail.breakdown_prices.driver_allowance}</p>
                                <p><strong>Service fee:</strong> {detail.breakdown_prices.tax_services}</p>
                                <p><strong>Total Amount:</strong> {detail.total_price}</p>
                                
                            </div>
                        ))}
                    </div>

                )}
                <div className={styles.drive}>
                    {driverDetail.length > 0 ? (
                        <>
                            <h3>Assigned Driver Details</h3>
                            {driverDetail.map((driv, index) => (
                                <div key={index} className={`${styles.driverDetails}`}>
                                    <span><strong>Driver id:</strong> {driv.driver_ID || driv.driver_id}</span>
                                    <span><strong>Name:</strong> {driv.driver_name}</span>
                                    <span><strong>Contact:</strong> {driv.driver_contact}</span>
                                    <span><strong>Address:</strong> {driv.driver_address}</span>
                                    <span><strong>Email:</strong> {driv.driver_email}</span>
                                    <span><strong>License no:</strong> {driv.driving_license}</span>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <h3>Available Drivers</h3>
                            {driver.length > 0 ? (
                                driver.map((dri) => (
                                    <div
                                        key={dri.driver_id}
                                        className={`${styles.driverDetails} ${selected === dri.driver_id ? styles.selected : ''}`}
                                        onClick={() => handleSelectDriver(dri.driver_id)}
                                    >
                                        <span><strong>Driver id:</strong> {dri.driver_id}</span>
                                        <span><strong>Name:</strong> {dri.driver_name}</span>
                                        <span><strong>Contact:</strong> {dri.driver_contact}</span>
                                        {selected === dri.driver_id && (
                                            <>
                                                <span className={styles.ddd}>Assign {dri.driver_name} to this trip</span>
                                                <button onClick={handleSelection} className={styles.assign}>Assign</button>
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No drivers available at the moment</p>
                            )}
                        </>
                    )}
                </div>

            </div>


        </div>
    )
}

export default Bookingdetails