import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./header.module.css";
import { faBars, faChevronLeft, faChevronRight, faUser } from "@fortawesome/free-solid-svg-icons";

const Header = ({ user, isOpen, toggleSidebar }) => {


  return (
    <header className={styles.header}>

      <div className={styles.cpLogo}>
        <span
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </span>
        <img src="/UraCab.png" alt="UC" />
        <span className={styles.ura}>UraCab</span>

      </div>
      <div className={styles.name}>
        <div className={styles.lab}>
          <span>{user.username}</span>
          <span>{user.role}</span>
        </div>
        <div className={styles.profile}><FontAwesomeIcon icon={faUser} /></div>
      </div>


    </header>
  );
};

export default Header;
