import React, { useState } from "react";
import styles from "./login.module.css";
import axios from "axios";

function Login() {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/login`,
        formData,
        { timeout: 1000 }
      );

      if (response.status !== 200) {
        throw new Error(error);
      } else {
        const token = response.data.token;
        const role = response.data.role;
        console.log(role);
        const username = response.data.username;
        setUserCredentials(username, role, token).then(() => {
          window.location.replace("/");
        });
      }
    } catch (error) {
      
      const errorMessage =
        error.response?.data?.errorMessage ||
        " ..Server down.."

      setError(errorMessage);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const setUserCredentials = (username, role, token) => {
    return new Promise((resolve) => {
      document.cookie = `jwt=${token}; path=/; max-age=36600; ''; samesite=strict;`;
      document.cookie = `username=${username}; path=/; max-age=36600; ''; samesite=strict;`;
      document.cookie = `role=${role}; path=/; max-age=36600; ''; samesite=strict;`;
      resolve();
    });
  };

  return (
    <div className={styles.whole}>
      <div className={styles.logi}>
        <h1>Login</h1>
        <form onSubmit={handleSubmit} className={styles.frm}>
          <label htmlFor="username">Name:</label>{" "}
          <input
            type="text"
            placeholder="enter username"
            value={formData.username}
            name="username"
            onChange={handleChange}
            className={styles.pass}
          />
          <label htmlFor="role">Role:</label>
          <select
            value={formData.role}
            name="role"
            onChange={handleChange}
            className={styles.select}
          >
            <option value="" disabled>
              Select your role
            </option>
            <option value="Chief Executive Officer">Chief Executive Officer</option>
            <option value="Chief Technical Officer">Chief Technical Officer</option>
            <option value="Joint Chief Technical Officer">Joint Chief Technical Officer</option>
          </select>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            placeholder="*****"
            value={formData.password}
            name="password"
            onChange={handleChange}
            className={styles.pass}
          />
          <button type="submit" className={styles.buton}>
            Login
          </button>
        </form>
        {error && <div className={styles.err}>{error}</div>}
      </div>
    </div>
  );
}

export default Login;