import React, { useEffect, useRef, useState } from "react";
import styles from "./profile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faPlus,
    faCheck,
    faCancel,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import MessageDisplay from "../../../components/message/message";
import CompressImage from "../../../helper/compressor/compressor";
import Rating from "../../../helper/rating/rating";
import { useDriverContext } from '../../context/DriverContext'

const ratings = { rating: 2, years: 7 };

const New = ({ driver_ID}) => {
    const { setActivationClick } = useDriverContext();

    const [isEditing, setIsEditing] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const profileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(null);
    const [initialImage, setInitialImage] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [profile, setProfile] = useState([])

    const driverActive = async () => {
        try {
            const dActivate = await axios.get(`${process.env.REACT_APP_URL}/api/drivers/activateinitial/${driver_ID}`)
            if (dActivate.data.specificDriver[0].activation === "deactivated") {
                setIsActive(false)
            } else if (dActivate.data.specificDriver[0].activation === "activated") {
                setIsActive(true)
            }
            setProfile(dActivate.data.specificDriver[0])
            console.log("etu???",dActivate.data.specificDriver[0])
            // const imagePath = `${process.env.REACT_APP_URL}/image/documents/fetch?imgpath=${dActivate.data.path}`

            const imagePath = `${process.env.REACT_APP_SIR}/${dActivate.data.specificDriver[0].path}`
            setInitialImage(imagePath);
        } catch (error) {
            setMessage({ type: "error", message: "Updating driver status failed " })
        }

    }

    useEffect(() => {
        driverActive()
        setPreviewImage(null);
        setIsEditing(false);
    }, [driver_ID]);

    const handleEdit = () => {
        setIsEditing(!isEditing);
        if (!isEditing) {
            setPreviewImage(null);
        }
    };
    const tempUrl = initialImage;

    const handleImagePreview = (event) => {
        const fileI = event.target.files[0];
        if (fileI) {
            CompressImage(fileI, 0.07)
                .then((compressedFile) => {
                    const previewURL = URL.createObjectURL(compressedFile);
                    setPreviewImage(previewURL);
                    setFile(compressedFile);
                })
                .catch((error) => {
                    console.error("Compression error:", error);
                });
        }
    };
    const handleSubmit = async () => {
        if (!file) {
            setMessage({ type: "error", message: "No profile selected." });
            setIsEditing(false);
            return;
        }

        const compressedFile = file;
        const newFile = new File([compressedFile], compressedFile.name, {
            type: compressedFile.type || "image/jpeg",
        });
        const formData = new FormData();
        // formData.append("passport", newFile);
        // formData.append("driver_ID", driver_ID);

        formData.append("type", "passport");
            formData.append("file", newFile);
            formData.append("name", "Passport Photo");

        try {
            const token = document.cookie
                .split("; ")
                .find((row) => row.startsWith("jwt="))
                .split("=")[1];
            const docType = "passport";
            // const url = `${process.env.REACT_APP_URL}/image/documents/updatefile/${docType}/${driver_ID}`;

            const response = await axios.post(
                // `${process.env.REACT_APP_URL}/image/documents/updatefile/${inputName}/${driver_ID}`,
                `${process.env.REACT_APP_UPLOAD}/api/documents/file?driver_Id=${driver_ID}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
                setMessage({ type: "success", message: response.data.message });
            }
        } catch (error) {
            console.log(error);
            setMessage({ type: "error", message: error.response.data.message || "failed" });
            setInitialImage(tempUrl);
            setPreviewImage(null);
            setFile(null);
        }

        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setPreviewImage(tempUrl)
    };
    const handleActivation = async () => {
        const activationURL = `${process.env.REACT_APP_URL}/api/drivers/activate`
        const action = isActive ? "deactivated" : "activated";

        const token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("jwt="))
            .split("=")[1];

        const data = {
            driver_ID: driver_ID,
            activation: action,
        };
        console.log("here we go", data)
        try {

            console.log(action);
            const response = await axios.post(activationURL, data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            // console.log("hjdshfhdgfhasjfhasf", response.data.message)
            if (response.data.message === "Success") {
                setIsActive((prev) => !prev);
                let status
                if(action==="activated"){
                        status="offline"
                }
                else {
                    status=null
                }
                setActivationClick({
                    driver_ID,
                    activation: action,
                    status   
                  });
            }
        } catch (error) {
            setMessage({ type: "error", message: error.response.data.message })
            console.log("hereedzvdjcvc we are", error)

        }
    }
    const imgUrl = previewImage ? previewImage : initialImage

    return (
        <div className={styles.new}>
            {console.log(profile.total_trips)}
            <div className={styles.heading}>
                <h2>Profile</h2>
                <label className={`${styles.act} ${isActive ? styles.yes : styles.no}`}>
                    <span>{isActive ? "Activated" : "de-activated"}</span>
                </label>
                {!isEditing ? (
                    <button className={styles.button} onClick={handleEdit} type="button">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                ) : (
                    <div className={styles.buttonContainer}>
                        <button
                            className={`${styles.button} ${styles.check}`}
                            onClick={handleSubmit}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                            className={`${styles.button} ${styles.cancel}`}
                            onClick={handleCancel}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faCancel} />
                        </button>
                    </div>
                )}
            </div>
            <div className={styles.ima}>
                <img src={imgUrl} alt="No Profile" loading="lazy"
                />
                {isEditing && (
                    <>
                        <input accept=".jpeg,.jpg" type="file" ref={profileRef} style={{ display: "none" }} onChange={handleImagePreview} />
                        <button
                            className={`${styles.button} ${styles.plus}`}
                            onClick={() => profileRef.current.click()}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </>
                )}
            </div>

            <div className={styles.newT}>
                <p><span>Name :</span><span><strong>{profile.driver_name}</strong></span></p>
                <p><span>Medium :</span><span><strong>{profile.medium_type}</strong></span></p>
                <p><span>Contact :</span><span><strong>{profile.driver_contact}</strong></span></p>
                <p><span>Cab id :</span><span><strong>{profile.cab_id}</strong></span></p>
                <p><span>Driver license :</span><span><strong>{profile.driving_license || profile.license_no}</strong></span></p>
                <button type="button" onClick={handleActivation} className={`${styles.buttonActivate}`}>
                    {isActive ? "Deactivate" : "Activate"}
                </button>
            </div>
            <div className={styles.statsContainer}>

                <div className={styles.rating}>
                    <span>{profile.total_trips}</span>
                    <span>Trips</span>
                </div>
                <div className={styles.rating}>
                    {profile.driver_ratings ? profile.driver_ratings.slice(0, 3) : "Not yet rated"}
                    {profile.driver_ratings && <Rating rating={profile.driver_ratings} />}
                    <span>Rating</span>
                </div>
                <div className={styles.rating}>
                        <span>{ratings.years}</span>
                        <span>Years</span>
                    </div>

            </div>

            <MessageDisplay message={message} />
        </div >
    );
};

export default New;
