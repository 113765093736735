import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./driver.module.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEye,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useDriverContext } from "../context/DriverContext";


const Driver = () => {
  const [drivers, setDrivers] = useState([]);
  const [searchDriver, setSearchDriver] = useState("");
  const [searchedDrivers, setSearchedDrivers] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [totalPage, setTotalPage] = useState('');
  const [page, setPage] = useState(1);

  const [driverData, setDriverData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const [debounce, setDebounce] = useState()
  const [total, setTotal] = useState()
  const [totalFetchedDrivers, setTotalFetchedDrivers] = useState(0);

  // const handleParent = (driver_ID, newStatus) => {
  //   console.log(`  from the actual parent Driver ${driver_ID} activation status updated to: ${newStatus}`);
  // };
  
  const handleDriverClick = (driver) => {
    const driver_ID = driver.driver_ID;
    navigate(`/drivers/${driver_ID}`);
  };


  const fetchDrivers = async (pageNum) => {
    try {
      if (driverData[pageNum]) {
        setDrivers(driverData[pageNum]);
        return;
      }
      console.log("not reaching here???")
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/drivers?limit=${"13"}&page=${page}`
      );
      const fetchedDrivers = response.data.drivers;
      const total = response.data.totalList;
      setTotal(total)
      setDrivers(fetchedDrivers)

      setTotalFetchedDrivers((prevCount) => prevCount + fetchedDrivers.length);
      console.log(fetchedDrivers)
      setDriverData((prevData) => ({ ...prevData, [pageNum]: fetchedDrivers }));
      setTotalPage(Math.ceil(total / 13));
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    console.log(totalFetchedDrivers)
    fetchDrivers(page);
  }, [page]);



  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounce(searchDriver);
    }, 300);
    return () => clearTimeout(handler);
  }, [searchDriver]);

  useEffect(() => {
    const searchDrivers = async () => {
      if (!debounce) {
        setSearching(false);
        setSearchedDrivers([]);
      } else {
        setSearching(true);
        try {
          const result = await axios.get(
            `${process.env.REACT_APP_URL}/api/drivers/search`,
            { params: { query: debounce } }
          );

          setSearchedDrivers(result.data.drivers);
        } catch (error) {
          console.error("Error searching drivers:", error.response);
          // setNodrivers(error.response.data.message);
          return;
        }
      }
    };
    searchDrivers();
  }, [debounce]);
  
  const drivers2 = !isSearching ? drivers : searchedDrivers;
 
  const isOnDriverDetailsPage = location.pathname.startsWith("/drivers/");
  const { activationClick } = useDriverContext();

  useEffect(() => {
    if (activationClick) {
      const updatedDrivers = drivers.map((driver) =>
        driver.driver_ID === activationClick.driver_ID
          ? { ...driver, activation: activationClick.activation,status:activationClick.status }
          : driver
      );
      setDrivers(updatedDrivers);

      const updatedDriver = searchedDrivers.map((driver) =>
        driver.driver_ID === activationClick.driver_ID
          ? { ...driver, activation: activationClick.activation,status:activationClick.status }
          : driver
      );
      setSearchedDrivers(updatedDriver);
    }
  }, [activationClick]);

  return (
    <div className={styles.dm}>
      {!isOnDriverDetailsPage ? (
        <>
          <h3 className={styles.driverHeading}>Driver Management </h3>

          <div className={styles.driver}>
            <form>
              <input
                type="text"
                value={searchDriver}
                onChange={(e) => setSearchDriver(e.target.value)}
                placeholder="Search for a driver..."
              />
              <div>Filter by type

                <input type="checkbox"></input>
              </div>
            </form>
            <div className={styles.dcontainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Driver ID</th>
                    <th>Name</th>
                    <th>Cab ID</th>
                    <th>Medium</th>
                    <th>Verified</th>
                    {/* <th>Status</th> */}
                    <th>Action</th>
                    <th>App Status</th>
                  </tr>
                </thead>
                <tbody>
                  {drivers2.length > 0 ? drivers2.map((driver, index) => (
                    <>
                      <tr key={index} >
                        <td>{driver.driver_ID}</td>
                        <td>{driver.driver_name}</td>
                        <td>{driver.cab_id}</td>
                        <td>{driver.medium_type}</td>
                        <td>
                          {driver.verification ?
                            <div className={styles.vercon}>
                              Verified
                              <span>
                                <FontAwesomeIcon icon={faCertificate} className={styles.certificate} />
                                <FontAwesomeIcon icon={faCheck} className={styles.check} />
                              </span>
                            </div>
                            :
                            "Not verified"}
                        </td>
                        {/* <td>
                          <span className={`${styles.status} ${driver.activation === "activated" ? styles.on : styles.off}`}>
                            <strong>
                              {driver.activation}
                            </strong>
                          </span>
                        </td> */}
                        <td>
                          <button type="button" className={`${styles.buton} `} onClick={() => handleDriverClick(driver)}>
                            <FontAwesomeIcon icon={faEye} /> View
                          </button>
                        </td>
                        <td>
                          {driver.status?driver.status :"Not activated"}
                          <span className={`${styles.status} ${driver.status ? driver.status === "online" ? styles.activated : styles.deactivated : ""}`}>
                          </span>
                        </td>

                      </tr>
                    </>
                  )) : ""
                  }
                  { page === totalPage ? <tr><td colSpan="8">All drivers Fetched</td></tr> : ""}

                </tbody>
              </table>
            </div>
            {!isSearching && <div className={styles.pagination}>
              <button className={styles.prev} onClick={() => setPage(page - 1)} disabled={page <= 1}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <span>Page <b>{page}</b> of {totalPage}</span>
              <button className={styles.next} onClick={() => setPage(page + 1)} disabled={page === totalPage}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>}
          </div>
        </>
      ) : (
        <Outlet />
      )}

    </div>
  );
}

export default Driver
